.radio-btns:hover .radio-btns__btn:not(:hover) {
  filter: grayscale(100%);
  opacity: 0.75;
}

.radio-btns:hover .radio-btns__btn:not(:hover)::after {
  background-color: rgba(#000, 0.5);
}

@media (min-width: 30rem) {
  .radio-btns {
    grid-template-columns: repeat(3, 150px);
  }
}

.radio-btns__btn {
  position: relative;
  cursor: pointer;
  transition: .3s;
}

.radio-btns__btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0);
  transition: .3s;
}

.radio-btns__btn__img {
  --g: 3px;
  /* the gap */
  --b: 3px;
  /* border thickness*/
  --c: #007FFF;
  /* the color */


  padding: calc(var(--g) + var(--b));
  --_c: #0000 0 25%, var(--c) 0 50%;
  --_g1: repeating-linear-gradient(90deg, var(--_c)) repeat-x;
  --_g2: repeating-linear-gradient(180deg, var(--_c)) repeat-y;
  background:
    var(--_g1) var(--_p, 25%) 0, var(--_g2) 0 var(--_p, 125%),
    var(--_g1) var(--_p, 125%) 100%, var(--_g2) 100% var(--_p, 25%);
  background-size: 200% var(--b), var(--b) 200%;
  cursor: pointer;
  filter: grayscale(10%);
  transition: .3s;
}

.radio-btns__btn:hover {
  --_p: 75%;
  filter: grayscale(0%);
}